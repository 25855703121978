// src/pages/AboutPage.js
import React from 'react';
import img1 from '../assets/images/AOTC.png'; // Example image
import ameron from '../assets/suhaib-clients/ameron.png';
import amiantit from '../assets/suhaib-clients/amiantit.png';
import aramco from '../assets/suhaib-clients/aramco.png';
import aribiandrilling from '../assets/suhaib-clients/aribiandrilling.png';
import halliburton from '../assets/suhaib-clients/halliburton.png';
import sabic from '../assets/suhaib-clients/sabic.png';
import CTASection from '../components/CTASection';

const clients = [
    { src: ameron, alt: 'Ameron' },
    { src: amiantit, alt: 'Amiantit' },
    { src: aramco, alt: 'Saudi Aramco' },
    { src: aribiandrilling, alt: 'Arabian Drilling' },
    { src: halliburton, alt: 'Halliburton' },
    { src: sabic, alt: 'SABIC' },
];

const AboutPage = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center mb-12">
                    <img src={img1} alt="Oil and Gas Industry" className="w-full md:w-1/2 rounded-lg shadow-lg mb-8 md:mb-0 md:mr-8" />
                    <div className="md:w-1/2">
                        <h1 className="text-4xl font-bold text-blue-900 mb-4">About Us</h1>
                        <p className="text-lg text-gray-700 mb-6 leading-relaxed">
                            American Overseas Trading Company (AOTC) is a leading provider of specialized materials and solutions for the oil and gas industry. With over 45 years of experience, we excel in delivering high-quality products tailored to meet the needs of our clients globally.
                        </p>
                        <p className="text-lg text-gray-700 mb-6 leading-relaxed">
                            Our expertise spans across import/export, logistics, and market analysis, ensuring our clients achieve operational success. We maintain strong partnerships with industry leaders and offer a comprehensive range of products, including piping materials, valves, safety equipment, and tools. At AOTC, our commitment to quality and customer satisfaction sets us apart.
                        </p>
                    </div>
                </div>

                {/* Client Logos Section */}
                <h2 className="text-3xl font-bold text-blue-900 text-center mb-8">Our Key Partners</h2>
                <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8 px-4">
                    {clients.map((client, index) => (
                        <div key={index} className="flex justify-center items-center transition-transform duration-300 hover:scale-105">
                            <img src={client.src} alt={client.alt} className="w-28 h-auto object-contain" />
                        </div>
                    ))}
                </div>

                {/* Vision and Mission Section */}
                <div className="mt-16 bg-gradient-to-r from-blue-800 to-blue-600 text-white py-12 px-6 rounded-xl shadow-2xl">
                    <div className="container mx-auto text-center space-y-8">
                        <div className="bg-white text-blue-900 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
                            <h2 className="text-3xl font-extrabold mb-4">Our Vision</h2>
                            <p className="text-lg leading-relaxed">
                                To be the preferred global partner in delivering exceptional solutions for the oil and gas industry, driven by innovation, integrity, and excellence.
                            </p>
                        </div>

                        <div className="bg-white text-blue-900 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
                            <h2 className="text-3xl font-extrabold mb-4">Our Mission</h2>
                            <p className="text-lg leading-relaxed">
                                We aim to provide our clients with top-quality products and reliable services, leveraging our expertise and extensive network to meet the dynamic needs of the industry.
                            </p>
                        </div>
                    </div>
                </div>


                {/* Call to Action Section */}
                <div className="mt-12 text-center">
                    <h2 className="text-3xl font-bold text-blue-900 mb-4">Partner with Us</h2>
                    <CTASection

                        title="Let's Build Your Project Together"
                        description="Discover how AOTC can support your projects and streamline your operations. Get in touch with our team today to learn more."
                        buttonText="Contact Us"
                        buttonLink="/contact"
                    />
                </div>
            </div>

        </div>
    );
};

export default AboutPage;
