// src/pages/CareersPage.js
import React from 'react';
import CTASection from '../components/CTASection';
import Swal from 'sweetalert2';
import { useState } from 'react';

import { useRef } from 'react';



const CareersPage = () => {

    const fileInputRef = useRef(null);


    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        contact: '',
        resume: null,
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'resume') {
            setFormData({ ...formData, resume: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if all fields are filled
        const { fullName, email, contact, resume } = formData;
        if (!fullName || !email || !contact || !resume) {
            Swal.fire({
                icon: 'error',
                title: 'Incomplete Form',
                text: 'Please fill in all the fields before submitting.',
                confirmButtonText: 'OK',
                confirmButtonColor: '#1d4ed8',
            });
            return;
        }

        // Show success Swal for 5 seconds
        Swal.fire({
            icon: 'success',
            title: 'Submitted!',
            text: 'Your request has been successfully submitted.',
            confirmButtonText: 'OK',
            confirmButtonColor: '#1d4ed8',
            timer: 5000,
            timerProgressBar: true,
        });

        // Reset form fields
        setFormData({
            fullName: '',
            email: '',
            contact: '',
            resume: null,
        });
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    return (
        <div className="py-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold text-blue-900 mb-8">Join Our Team</h1>
                <p className="text-lg text-gray-700 mb-6">
                    At AOTCLLC, we believe in fostering a collaborative and innovative work environment where employees can thrive and grow. If you’re looking for a dynamic career in trading and contracting, we’d love to hear from you!
                </p>




                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Full Name</label>
                        <input
                            type="text"
                            name="fullName"
                            placeholder="Your Full Name"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Contact</label>
                        <input
                            type="tel"
                            name="contact"
                            placeholder="Your Contact Number"
                            value={formData.contact}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Resume</label>
                        <input
                            type="file"
                            name="resume"
                            ref={fileInputRef}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit Application
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CareersPage;
