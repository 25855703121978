// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-6">
            <div className="container mx-auto text-center">
                <p>&copy; 2024 American Overseas Trading and Contracting LLC. All rights reserved.</p>

            </div>
        </footer>
    );
};

export default Footer;
