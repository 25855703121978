// src/components/Services.js
import React from 'react';
import { Link } from 'react-router-dom';

const services = [
    {
        title: 'Trading Services',
        description: 'Providing high-quality products and reliable logistics across the globe.',
        link: '/trading',
    },
    {
        title: 'Contracting Services',
        description: 'From planning to execution, we handle projects of any scale.',
        link: '/contracting',
    },
];

const Services = () => {
    return (
        <section className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold text-blue-900 mb-8">Our Services</h2>
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
                {services.map((service, index) => (
                    <div key={index} className="p-6 shadow-lg rounded-lg bg-white">
                        <h3 className="text-xl font-semibold text-red-600">{service.title}</h3>
                        <p className="mt-4 text-gray-700">{service.description}</p>
                        <Link to={service.link}>
                            <button className="mt-6 px-6 py-2 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors duration-300">
                                Learn More
                            </button>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;
