import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/AOTC.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="bg-white shadow-md py-4">
            <div className="container mx-auto flex justify-between items-center px-4">
                <Link to="/">
                    <img src={logo} alt="AOTCLLC Logo" className="w-16 cursor-pointer" />
                </Link>                <button
                    className="md:hidden text-gray-700 hover:text-red-600"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <i className="bi bi-list text-2xl"></i>
                </button>
                <nav className={`flex-col md:flex md:flex-row md:space-x-6 text-lg font-bold ${isOpen ? 'flex' : 'hidden'} md:flex`}>
                    <Link to="/" className="text-gray-700 hover:text-red-600 no-underline">HOME</Link>
                    <Link to="/trading" className="text-gray-700 hover:text-red-600 no-underline">TRADING SERVICES</Link>
                    <Link to="/contracting" className="text-gray-700 hover:text-red-600 no-underline">CONTRACTING SERVICES</Link>
                    <Link to="/careers" className="text-gray-700 hover:text-red-600 no-underline">CAREERS</Link>
                    <Link to="/about" className="text-gray-700 hover:text-red-600 no-underline">ABOUT US</Link>
                    <Link to="/contact" className="text-gray-700 hover:text-red-600 no-underline">CONTACT</Link>
                </nav>
                <div className="hidden md:flex items-center space-x-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="border rounded px-3 py-1 text-sm"
                    />
                    <button className="text-gray-700 hover:text-red-600"><i className="bi bi-search"></i></button>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
