// src/pages/ContactPage.js
import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        requestType: 'Request for Quotation',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        const { name, email, contact, requestType, description } = formData;

        // Check for empty fields
        if (!name) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your name.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!email) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your email address.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!contact) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please enter your contact number.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!requestType) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please select a request type.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        if (!description) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Input',
                text: 'Please describe your request.',
                confirmButtonColor: '#1d4ed8',
            });
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (!validateForm()) return;

        const formUrl = 'https://formspree.io/f/meoqgnrl'; // Replace with your Formspree endpoint URL

        try {
            const response = await fetch(formUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Submitted!',
                    text: 'Your request has been successfully submitted.',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#1d4ed8',
                });
                setFormData({
                    name: '',
                    email: '',
                    contact: '',
                    requestType: 'Request for Quotation',
                    description: '',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Submission Failed',
                    text: 'There was an issue submitting your request. Please try again later.',
                    confirmButtonColor: '#1d4ed8',
                });
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Submission Error',
                text: 'An error occurred while submitting the form. Please try again later.',
                confirmButtonColor: '#1d4ed8',
            });
        }
    };


    return (
        <div className="py-16 bg-gray-100 text-center">
            <h2 className="text-3xl font-bold text-blue-900 mb-8">Contact Us</h2>
            <p className="max-w-md mx-auto text-lg text-gray-700 mb-8">
                Please fill out the form below to submit your request. Select "Request for Quotation" or "Request for Information" to get started.
            </p>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto space-y-4">
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-3 border rounded"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 border rounded"
                    required
                />
                <input
                    type="text"
                    name="contact"
                    placeholder="Your Contact Number"
                    value={formData.contact}
                    onChange={handleChange}
                    className="w-full p-3 border rounded"
                    required
                />
                <select
                    name="requestType"
                    value={formData.requestType}
                    onChange={handleChange}
                    className="w-full p-3 border rounded"
                    required
                >
                    <option value="Request for Quotation">Request for Quotation (RFQ)</option>
                    <option value="Request for Information">Request for Information (RFI)</option>
                </select>
                <textarea
                    name="description"
                    placeholder="Describe your request"
                    value={formData.description}
                    onChange={handleChange}
                    className="w-full p-3 border rounded"
                    rows="5"
                    required
                />
                <button type="submit" className="px-6 py-3 bg-blue-900 text-white font-semibold rounded-lg hover:bg-blue-700">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactPage;
