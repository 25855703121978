import React from 'react';
import CTASection from '../components/CTASection';
import drawing3D from '../assets/specialized-services/3D-Drawing.jpg';
import drawingArchitectural from '../assets/specialized-services/ArchitecturalDrawing.png';
import drawingFabrication from '../assets/specialized-services/FabricationDrawing.jpeg';

const services = [
    {
        title: 'Architectural Drawings',
        description: 'Detailed architectural plans and designs for building projects.',
        img: drawingArchitectural,
    },
    {
        title: 'Fabrication Drawings',
        description: 'Comprehensive fabrication details for accurate construction.',
        img: drawingFabrication,
    },
    {
        title: '3D Designing',
        description: 'Precise 3D models for enhanced visualization and project planning.',
        img: drawing3D,
    },
];

const ContractingPage = () => {
    return (
        <div className="py-16 bg-gray-100">
            {/* Background Section */}
            <div className="relative">

                <div className="container mx-auto px-4 relative">
                    <h1 className="text-4xl font-bold text-blue-900 mb-8">Contracting Services</h1>
                    <p className="text-lg text-gray-700 mb-6">


                    </p>

                    {/* Service List with Icons */}
                    <ul className="list-disc pl-6 text-lg text-gray-700 mb-8">
                        <li className="mb-4 hover:text-red-600 transition-colors duration-200">
                            <span className="font-semibold">                        At American Overseas Trading Company (AOTC), we go beyond providing premium materials and equipment by offering a comprehensive range of technical services to support our clients’ projects from inception to completion. We specialize in creating 3D designs on Revit & AutoCAD, architectural drawings, and fabrication drawings tailored to the unique requirements of each project.
                            </span>
                        </li>
                        <li className="mb-4 hover:text-red-600 transition-colors duration-200">
                            <span className="font-semibold">                        Our team of skilled engineers and designers brings extensive experience in producing accurate, high-quality drawings essential for efficient project planning and execution. These technical drawings support various stages of our clients' projects, from initial design and engineering to construction and commissioning, ensuring alignment with project specifications and industry standards.
                            </span>
                        </li>
                        <li className="mb-4 hover:text-red-600 transition-colors duration-200">
                            <span className="font-semibold">By providing these critical services, AOTC further enhances our role as a comprehensive solutions provider, enabling us to deliver unmatched value and support to our clients in the oil and gas, power, utility, and chemical sectors. Our commitment to precision, reliability, and innovation underscores our dedication to helping our clients achieve operational success and project excellence.</span>
                        </li>

                    </ul>

                    {/* New Services Section */}
                    <div className="py-16">
                        <h2 className="text-3xl font-bold text-blue-900 mb-8">Our Specialized Services</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {services.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                                >
                                    <img
                                        src={service.img}
                                        alt={service.title}
                                        className="w-full h-40 object-cover rounded-lg mb-4"
                                    />
                                    <h3 className="text-xl font-semibold text-red-600 mb-2">{service.title}</h3>
                                    <p className="text-gray-700">{service.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Call-to-Action Section */}
                    <CTASection
                        title="Let's Build Your Project Together"
                        description="Reach out to us today to discuss your contracting needs and how we can assist you in delivering successful projects."
                        buttonText="Contact Us"
                        buttonLink="/contact"
                    />
                </div>
            </div>
        </div>
    );
};

export default ContractingPage;
