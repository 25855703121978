// src/components/HeroSection.js
import React from 'react';
import heroImage from '../assets/images/hero-bg.jpg';
import { Link } from 'react-router-dom';


const HeroSection = () => {
    return (
        <section
            className="relative h-[80vh] bg-cover bg-center flex items-center justify-center"
            style={{ backgroundImage: `url(${heroImage})` }}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10 text-center text-white px-4">
                <h1 className="text-4xl md:text-6xl font-arial">American Overseas Trading and Contracting</h1>
                <p className="mt-2 text-lg">Your Trusted Partner in Global Trading & Contracting</p>
                <Link to="/about">
                    <button className="mt-6 px-8 py-3 bg-red-600 text-white font-semibold rounded-full hover:bg-red-700">
                        Discover More
                    </button>
                </Link>

            </div>
        </section>
    );
};

export default HeroSection;
