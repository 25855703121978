import React from 'react';

const TopBar = () => {
    return (
        <div className="bg-gray-800 text-white text-sm py-2">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 space-y-2 md:space-y-0">
                <p className="text-center md:text-left">
                    One stop to all your industrial solutions – Contact Us Today!
                </p>
                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 items-center">
                    <span><i className="bi bi-telephone-fill"></i> +1 (240) 885-1367</span>
                    <span><i className="bi bi-telephone-fill"></i> +1 (201) 515-7384</span>
                    <span><i className="bi bi-envelope-fill"></i> info@aotcllcus.com</span>
                    <span><i className="bi bi-instagram"></i></span>
                    <span><i className="bi bi-linkedin"></i></span>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
